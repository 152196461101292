<template>
  <v-main app>
    <v-container>
    <component :is="activeComponent"></component>
  </v-container>
  </v-main>
</template>

<script>
import JM2408 from './components/JM2408.vue';
import clickApi from "../../../api/public/click";

export default {
  components: {
    JM2408
  },
  data: () => ({
  }),
  computed: {
    activeComponent () {
      return this.$route.params.token
    }
  },
  async created() {
  },
  methods: {
    submit() {
    },
  },
};
</script>
